.messages {
  display: flex;
  gap: 10px;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.messages::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.messages {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.messages__jump {
  position: relative;
}

.message-form {
  align-items: flex-end;
  background-color: var(--tg-theme-bg-color);
  display: flex;
}

.message-form__submit-wrapper {
  flex-basis: 50px;
  padding: 1px 0px;
}

.message-form__submit {
  background: unset;
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.message-form__textarea {
  flex: unset;
  padding: 17px 12px;
  scroll-padding: 17px 12px;
}
