.login {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.numbers-panel-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;

  flex: 1;
}

.numbers-panel {
  align-items: center;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 1fr));
  justify-content: center;
  /* height: 100%; */
  padding: 20px;

  /* height: 100%; */
  /* width: 100%; */

  /* flex: 1; */
}

.numbers-panel__item {
  /* aspect-ratio: 1 / 1; */
  /* height: 100%; */
  /* width: 100%; */
}

.numbers-panel__item > button {
  aspect-ratio: 1 / 1;
  background-color: var(--tg-theme-bg-color);
  border-radius: 50%;
  color: var(--tg-theme-hint-color);
  font-size: calc(min((100vh - 80px - 50px - 5 * 20px) / 4, (100vw - 4 * 20px) / 3) / 1.75);
  font-weight: 100;
  height: 60px;
  height: calc(min((100vh - 80px - 50px - 5 * 20px) / 4, (100vw - 4 * 20px) / 3));
}
