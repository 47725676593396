:root {
  --tg-theme-bg-color: #1d2733;
  --tg-theme-button-color: #50a7ea;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-hint-color: #7d8b98;
  --tg-theme-link-color: #50a7ea;
  --tg-theme-secondary-bg-color: #151e27;
  --tg-theme-text-color: #ffffff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  color: var(--tg-theme-text-color);
  background-color: var(--tg-theme-secondary-bg-color);
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

a, a:visited {
  color: var(--tg-theme-link-color);
}

button {
  align-items: center;
  background-color: var(--tg-theme-button-color);
  border: 0;
  color: var(--tg-theme-button-text-color);
  display: flex;
  filter: brightness(1);
  justify-content: center;
  outline: 0;
}

button:active {
  filter: brightness(0.8);
  transition: filter 0.1s;
}

input {
  background-color: var(--tg-theme-bg-color);
  border: 0;
  caret-color: var(--tg-theme-button-color);
  font-size: 18px;
  outline: none;
  padding: 16px 12px;
  width: 100%;
}

/* Hide input number arrows for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide input number arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  background-color: var(--tg-theme-bg-color);
  border: 0;
  caret-color: var(--tg-theme-button-color);
  color: var(--tg-theme-text-color);
  flex: 1 0 auto;
  font-size: 16px;
  outline: none;
  padding: 17px 12px;
  resize: none;
  scroll-padding: 17px 12px;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
