.centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

.centered__content {
  background-color: var(--tg-theme-bg-color);
  border-radius: 20px;
  color: var(--tg-theme-hint-color);
  padding: 5px 12px;
}
