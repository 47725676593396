.accounts {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.accounts > * {
  border-bottom: 1px solid var(--tg-theme-bg-color);
  text-decoration: none;
}
