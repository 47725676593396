.message {
  display: grid;
  grid-template-columns: 50px auto 1fr;
  scroll-margin: 10px;
}

.message__avatar {
  align-items: flex-end;
  display: flex;
}

.message--has-following {
  margin-bottom: -8px;
}

.message--hidden .message__content {
  font-size: 13px;
  opacity: 0.25;
  padding: 12px;
}

.message__content {
  background-color: var(--tg-theme-bg-color);
  border: 1px solid var(--tg-theme-bg-color);
  border-radius: 7px 12px 12px 0px;
  display: inline-block;
  overflow: hidden;
  padding: 10px;
  position: relative;
  white-space: pre-wrap;
  word-break: break-word;
}

.message--has-following .message__content {
  border-radius: 7px 12px 12px 7px;
}

.message:not(.message--has-following) .message__content::before {
  background-image: radial-gradient(circle at 0% 15%, transparent 15px, var(--tg-theme-bg-color) 15px);
  bottom: 0px;
  content: '';
  display: block;
  height: 15px;
  left: -15px;
  position: absolute;
  width: 15px;
}

.message__sender {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message__text {

}

.message__footer {
  color: var(--tg-theme-hint-color);
  float: right;
  font-size: 12px;
  line-height: 12px;
  margin-top: -12px;
  overflow: hidden;
  text-align: right;
}

.message__time {

}
