.chat {
  display: grid;
  gap: 0 10px;
  grid-template-columns: 50px auto 30px;
  padding: 10px;
}

.chat__logo {
  background: var(--tg-theme-bg-color);
  border-radius: 50%;
  content: '';
  width: 50px;
  height: 50px;
}

.chat__main, .chat__stats {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.chat__main {
  overflow: hidden;
  width: 100%;
}

.chat__heading {
  align-items: center;
  color: var(--tg-theme-text-color);
  display: flex;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat__rest {
  align-items: center;
  color: var(--tg-theme-hint-color);
  display: flex;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat__time {
  align-items: center;
  color: var(--tg-theme-hint-color);
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  height: 16px;
  overflow: hidden;
}

.chat__unread {
  align-items: center;
  color: var(--tg-theme-hint-color);
  display: flex;
  justify-content: flex-end;
  height: 24px;
  overflow: hidden;
}
